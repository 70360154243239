.blue-map {
  padding-top: 160px;
  background-color: $light-blue-2;
  .container {
    position: relative;
    &::before {
      @include pseudo;
      width: 724px;
      height: 409px;
      @include image('../images/backgrounds/blue-map.png');
      left: 15px;
      top: 75px;
    }
  }
  .icon-item {
    &.for-map {
      margin-bottom: 350px;
    }
    .icon-item-headline {
      b {
        display: block;
      }
    }
  }
  .blue-map-items {
    padding-right: 90px;
  }
}

@media (max-width: 1599px) {
  .blue-map {
    padding-top: 100px;
    .container {
      &::before {
        width: 605px;
        height: 344px;
        top: 95px;
      }
    }
    .icon-item {
      &.for-map {
        margin-bottom: 260px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .blue-map {
    .container {
      &::before {
        top: 185px;
      }
    }
    .icon-item {
      &.for-map {
        margin-bottom: 310px;
      }
    }
  }
}

@media (max-width: 991px) {
  .blue-map {
    padding-top: 60px;
    .blue-map-items {
      padding-right: 0;
    }
    .container {
      &::before {
        width: 430px;
        height: 255px;
        top: 210px;
      }
    }
    .icon-item {
      &.for-map {
        margin-bottom: 230px;
      }
    }
  }
}

@media (max-width: 767px) {
  .blue-map {
    padding-top: 40px;
    padding-bottom: 10px;
    .container {
      &::before {
        display: none;
      }
    }
    .icon-item {
      margin-bottom: 30px;
      &.for-map {
        margin-bottom: 30px;
      }
    }
  }
}