.blue-doctor {
  padding: 235px 0 65px 0;
  background-color: #2f94df;
  background-image: url('../images/backgrounds/blue-doctor.jpg');
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    background-image: none
  }
  .blue-doctor-text {
    width: 60%;
    padding-left: 110px;
    position: relative;
  }
  .blue-doctor-headline {
    color: #034ba1;
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
    b {
      color: #034ba1;
    }
    span {
      display: block;
      font-size: 18px;
      line-height: 38px;
    }
  }

  .btn-group {
    display: block;
    margin: 30px 0;

    a {
      border-top-right-radius: .25rem !important;
      border-bottom-right-radius: .25rem !important;
    }

    > a:first-of-type {
      float: left;

      @media only screen and (max-width: 1199px) {
        float: none;
      }
    }
    > a:last-of-type {
      float: right;
      padding: 0 40px;
      line-height: 53px;

      @media only screen and (max-width: 1199px) {
        display: block;
        float: none;
      }
    }
  }

  .link {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    color: #fff;
  }
}

@media (max-width: 1599px) {
  .blue-doctor {
    &::before {
      margin-left: -115px;
    }
    .blue-doctor-text {
      width: 62%;
      padding-left: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .blue-doctor {
    &::before {
      margin-left: -210px;
    }
    padding-top: 210px;
    .blue-doctor-headline {
      width: 78%;
      font-size: 26px;
    }
  }
}

@media (max-width: 991px) {
  .blue-doctor {
    padding-top: 90px;
    .blue-doctor-text {
      padding-left: 0;
      width: 66%;
    }
    .blue-doctor-headline {
      width: 90%;
    }
    &::before {
      width: 730px;
      height: 440px;
      margin-left: -250px;
    }
  }
}

@media (max-width: 991px) {
  .blue-doctor {
    padding-bottom: 40px;
    .blue-doctor-text {
      width: 100%;
    }
    .blue-doctor-headline {
      width: 100%;
      font-size: 22px;
    }
    &::before {
      display: none;
    }
  }
}