.staff {
  text-align: center;
  padding: 80px 0;
  .staff-headline {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .staff-text {
    margin-bottom: 50px;
  }
  .staff-item-headline {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .staff-item-text {
    margin-bottom: 5px;
  }
  .staff-item-contact {
    position: relative;
    display: inline-block;
    margin-top: 5px;
    .icon {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      fill: $blue;
    }
    &.phone {
      padding-left: 44px;
      .icon {
        @include size(30px);
      }
    }
    &.email {
      padding-left: 50px;
      margin-top: 15px;
      .staff-item-contact-link {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .icon {
        @include size(35px);
      }
    }
  }
  .staff-item-contact-link {
    font-weight: bold;
    display: block;
    line-height: 1.6;
  }
}

@media (max-width: 1199px) {
  .staff {
    .staff-headline {
      font-size: 26px;
    }
  }
}

@media (max-width: 991px) {
  .staff {
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  .staff {
    padding: 40px 0 0 0;
    .staff-headline {
      font-size: 22px;
    }
    .staff-text {
      margin-bottom: 30px;
    }
    .staff-item-contact {
      margin-bottom: 40px;
    }
    .staff-item-headline {
      font-size: 20px;
    }
  }
}