
header .logo {
  margin-top: 35px;

  @media only screen and (max-width: 1199px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 18px;
  }
}

header .hamburger {

  @media only screen and (max-width: 1199px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 18px;
  }
}

.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header .menu {
  display: flex;
  align-items: center;

  header[class*=fixed] & {
    margin-top: 35px;
  }
}

.main-header .menu li {

  &:last-of-type::before {
    display: none;
  }

  &:last-of-type a {
    padding: 15px 20px;
    color: #fff;
  }

  &:last-of-type img {
    width: 23px;
    margin-right: 10px;
  }
}

header nav {

  @media only screen and (max-width: 1199px) {
    display: none;
  }
}