
.client-box {
  padding: 50px 100px;
  border-radius: 3px;
  color: #fff;
  background-color: rgb(3, 75, 161);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 991px) {
    padding: 50px 30px;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 0 30px;
  }

  h3 {
    color: #fff;
  }

  p,a {
    color: #8cb4e3;
  }

  a {
    text-decoration: underline;
  }
}

.client-box_inside {
  position: relative;
  margin: 60px 0 0;
  padding: 40px 0 0;
  p { color: #fff; }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: #fff;
  }

  img {
    max-width: 100%;
  }
}
