.other-sites {
  background-color: #034ba1;
  text-align: left;
  color: #fff;
  .other-sites-headline {
    width: 50%;
    margin: auto;
    margin-bottom: 65px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    b {
      color: #fff;
    }
  }
  .other-sites-item {
    display: block;
    color: $base-color;
    font-size: 14px;
    &:hover {
      color: $base-color;
      text-decoration: none;
      .other-sites-logo {
        transform: scale(1.1);
      }
    }
    .other-sites-logo {
      max-width: 100%;
      height: 30px;
      margin-bottom: 20px;
      @include transition;
    }
    .other-sites-logo-fl {
      height: auto;
      margin-bottom: 28px;
    }
    .other-sites-item-headline {
      margin-bottom: 25px;
      font-size: 24px;
    }
    .other-sites-item-text {
      margin-bottom: 0;
      color: #8cb4e3;
    }

    span {
      display: block;
      margin: 10px 0 0;
      text-decoration: underline;
      color: #fff;
    }
  }
}

@media (max-width: 1599px) {
  .other-sites {
    .other-sites-headline {
      width: 56%;
    }
  }
}

@media (max-width: 1199px) {
  .other-sites {
    .other-sites-headline {
      width: 58%;
      font-size: 26px;
    }
    .other-sites-item {
      .other-sites-logo {
        height: 20px;
        margin-bottom: 30px;
      }
      .other-sites-item-headline {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .other-sites {
    padding-bottom: 0;
    .other-sites-headline {
      width: 100%;
      font-size: 22px;
      margin-bottom: 45px;
    }
    .other-sites-item {
      margin-bottom: 50px;
      .other-sites-logo {
        margin-bottom: 20px;
      }
      .other-sites-headline {
        margin-bottom: 10px;
      }
    }
  }
}