

footer .footer-info .footer-info-headline {
  margin-bottom: 0;
}

.footer-info-headline + a {
  position: relative;
  top: -5px;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
