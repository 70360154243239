.accordion {
  text-align: left;
  .item {
    padding: 25px 0 25px 70px;
    border-top: 1px solid #d4e3ee;
    .item-headline {
      font-size: 18px;
      font-weight: 500;
      font-family: 'Rubik',sans-serif;
      color: $blue;
      line-height: 24px;
      cursor: pointer;
      margin: 0;
      margin-bottom: 25px;
      @include transition(300ms);
      position: relative;
      &::before {
        @include size(40px);
        @include pseudo;
        left: -70px;
        top: 50%;
        margin-top: -20px;
        border-radius: 100%;
        border: 2px solid $vibrant-blue;
        @include transition;
        background-color: $vibrant-blue;
      }
      &::after {
        @include pseudo;
        width: 14px;
        height: 2px;
        left: -57px;
        top: 50%;
        margin-top: -1px;
        @include image('../images/svg/minus-white.svg');
      }
      &.collapsed {
        margin-bottom: 0;
        &::before {
          background-color: transparent;
          border-color: $blue;
        }
        &::after {
          height: 14px;
          margin-top: -7px;
          @include image('../images/svg/plus-blue.svg');
        }
      }
    }
    .item-text {
      p {
        line-height: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .accordion {
    .item {
      padding-left: 40px;
      .item-headline {
        &::before {
          @include size(24px);
          top: 4px;
          margin-top: 0;
          left: -40px;

        }
        &::after {
          width: 10px;
          left: -33px;
          margin-top: 0;
          top: 15px
        }
        &.collapsed {
          &::after {
            margin-top: 0;
            top: 9px;
          }
        }
      }
    }
  }
}