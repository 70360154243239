.progress-pricelist {
  background-color: #fff;
  padding: 0;
  text-align: center;
  position: relative;
  .progress-pricelist-bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    .progress-pricelist-bg {

    }
  }
  .container {
    position: relative;
  }
  .blue-box {
    padding: 75px 60px 60px 60px;
    background-color: $vibrant-blue;
    position: absolute;
    width: 640px;
    right: 15px;
    top: -170px;
    z-index: 10;

    @media only screen and (max-width: 1599px) {
      right: -70px;
    }
    .blue-box-headline {
      color: #fff;
      font-size: 30px;
      margin-bottom: 30px;
      b {
        color: #fff;
      }
      span {
        display: block;
        font-size: 18px;
        font-weight: 300;
        line-height: 38px;
      }
    }
    .blue-box-text {
      color: #8cb4e3;
      width: 90%;
      margin: 0 auto;
    }
    .or-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .or-contact {
        padding-left: 45px;
        position: relative;
        .icon {
          left: 0;
          top: 14px;
          fill: $light-blue;
          @include size(30px);
          display: block;
        }
        span {
          display: block;
          font-size: 15px;
          margin-bottom: 3px;
          color: #fff;
        }
        a {
          display: block;
          font-size: 18px;
          font-family: 'Rubik', sans-serif;
          font-weight: 500;
          color: $light-blue;
          line-height: 1;
          @include transition;
          &:hover {
            color: $light-blue;
          }
        }
      }
      .or-text {
        color: #8cb4e3;
      }
    }
  }
  .blue-box-light {
    position: relative;
    top: 0;
    right: -30px;
    margin-top: -300px;
    background-color: #2f94df;
    z-index: 5;

    @media only screen and (max-width: 1599px) {
      right: 30px;
    }

    @media only screen and (max-width: 1199px) {
      top: 0 !important;
      margin-top: -200px;
    }

    @media only screen and (max-width: 991px) {
      top: 0 !important;
      right: 0 !important;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .blue-box-text {
      color: rgba(#fff, .6);
    }
  }
  .progress-pricelist-headline {

  }
  .progress-pricelist-text {
    width: 50%;
    margin: auto;
    margin-bottom: 0;
  }
  .progress-pricelist-items {
    margin: auto;
    z-index: 1;
    position: relative;
    .btn {
      margin-top: 30px;
    }
    .progress-pricelist-item {
      text-align: center;
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      border-radius: 3px;
      margin-bottom: 130px;
      .white-container {
        padding: 50px 30px 25px 30px;
        background-color: #fff;
        position: relative;
        @include border-radius-extended(3px,3px,0,0);
        .circle {
          position: absolute;
          @include size(80px);
          border: 2px solid $blue;
          border-radius: 100%;
          top: -40px;
          left: 50%;
          margin-left: -40px;
          .icon {
            @include size(40px);
            top: 50%;
            left: 50%;
            fill: $blue;
            transform: translate(-50%, -50%);
          }
        }
        .white-container-headline {
          font-size: 24px;
          color: $dark-blue;
          margin-bottom: 20px;
        }
        .white-container-text {
          margin-bottom: 0;
          line-height: 24px;
        }
        .btn {
          margin: 30px 0;
        }
      }
      .blue-container {
        min-height: 115px;
        background-color: $vibrant-blue;
        display: block;
        padding: 50px;
        position: relative;
        @include border-radius-extended(0,0,3px,3px);
        &::before {
          @include pseudo;
          @include size(24px);
          background-color: #fff;
          top: -12px;
          left: 50%;
          margin-left: -12px;
          border-radius: 100%;
        }
        .blue-container-text {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-family: 'Rubik', sans-serif;
          color: #fff;
          width: 100%;
          margin-bottom: 0;
        }
      }
      &.item-2 {
        margin-top: 220px;
      }
      &.item-3 {
        margin-top: -244px;
      }
      &.item-4 {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1199px) {
  .progress-pricelist {
    .blue-box {
      padding: 45px 40px 45px;
      width: 500px;
      top: -150px;
      right: 0;
    }
    .progress-pricelist-text {
      width: 70%;
    }
    .progress-pricelist-items {
      width: 100%;
    }
    .progress-pricelist-headline {
      font-size: 26px;
    }
  }
}

@media (max-width: 991px) {
  .progress-pricelist {
    .blue-box {
      width: 540px;
      top: -450px;
      right: 90px;
    }
    .progress-pricelist-items {
      .blue-container-text {
        display: block !important;
        padding: 20px 0;
        &:last-of-type {
          padding-top: 0 !important;
        }
      }
      .progress-pricelist-item {
        .blue-container {
          display: block;
          padding: 0 30px;
          span {
            display: block;
          }
        }
      }
    }
  }
  .progress-pricelist .progress-pricelist-items .btn {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .progress-pricelist {
    padding-top: 0;
    .blue-box {
      width: 100%;
      top: -60px;
      right: auto;
      position: relative;
      padding: 35px 30px;
      .or-container {
        flex-wrap: wrap;
        padding-bottom: 42px;
        position: relative;
        .or-contact {
          display: block;
          text-align: left;
          margin: auto;
          .icon {
            top: 10px;
          }
        }
        .or-text {
          width: 100%;
          padding: 10px 0;
        }
        .btn {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .blue-box-headline {
        font-size: 22px;
      }
      .blue-box-text {
        width: 100%;
      }
    }
    .progress-pricelist-bg-container {
      display: none;
    }
    .progress-pricelist-headline {
      font-size: 22px;
    }
    .progress-pricelist-text {
      width: 100%;
    }
    .progress-pricelist-items {
      .progress-pricelist-item {
        margin-bottom: 60px;
        &.item-2, &.item-3 {
          margin-top: 0;
        }
        .white-container {
          padding: 65px 20px 25px;
          .white-container-headline {
            font-size: 22px;
            margin-bottom: 15px;
          }
          &::before {
            @include size(80px);
            margin-left: -40px;
            top: -40px;
          }
          .circle {
            @include size(66px);
            margin-left: -33px;
            top: -33px;
            .icon {
              @include size(34px);
            }
          }
        }
      }
    }
  }
}

.progress-pricelist-padding {
  padding-top: 270px;

  @media only screen and (max-width: 1199px) {
    padding-top: 190px;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }
}
