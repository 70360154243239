.icon-item {
  font-size: 14px;
  .icon-item-headline {
    padding-left: 60px;
    color: $dark-blue;
    font-size: 22px;
    font-family: 'Rubik', sans-serif;
    position: relative;
    margin-bottom: 25px;
    .icon {
      @include size(36px);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      fill: $blue;
      &.bigger {
        @include size(46px);
        left: -5px;
      }
    }
  }
  p {
    width: 90%;
    margin: 0;
  }
  &.bigger {
    font-size: 16px;
    .icon-item-headline {
      font-size: 24px;
      padding-left: 65px;
      padding-top: 4px;
      .icon {
        @include size(45px);
      }
    }
    p {
      width: 95%;
    }
  }
  &.without-headline {
    padding-left: 85px;
    position: relative;
    font-size: 18px;
    line-height: 1.8;
    .icon {
      left: 0;
      top: 9px;
      @include size(50px);
      fill: $blue;
    }
  }
}

@media (max-width: 1599px) {
  .icon-item {
    &.without-headline {
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  .icon-item {
    &.bigger {
      .icon-item-headline {
        font-size: 20px;
        padding-top: 0;
        padding-left: 55px;
        .icon {
          @include size(40px);
        }
      }
    }
    .icon-item-headline {
      font-size: 20px;
      padding-left: 50px;
      .icon {
        &.bigger {
          @include size(35px);
          left: -3px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .icon-item {
    p {
      width: 100%;
    }
    &.bigger {
      p {
        width: 100%;
      }
    }
  }
}

.icon-item-link {
  position: relative;
  min-height: 130px;
  @media only screen and (max-width: 991px) {
    min-height: 1px;
  }
}

.icon-item-link .icon-item-headline { font-size: 18px; }

.icon-item-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  display: flex;
  align-items: flex-end;
  text-decoration: underline;

  @media only screen and (max-width: 991px) {
    position: static;
    display: inline-block;
    margin: 10px 0 0;
  }
}

.col-md-6:last-of-type .icon-item-link .icon-item-headline .icon {
  width: 46px;
  height: 46px;
}
