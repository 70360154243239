.mobile-menu, .menu-backdrop {
  display: none;
}

@media (max-width: 1199px) {
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: -340px;
    width: 320px;
    height: 100%;
    background-color: $dark-blue;
    z-index: 9999;
    overflow: auto;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    @include transition(300ms);
    .mobile-menu-main {
      background-color: $vibrant-blue;
      padding-top: 40px;
      box-shadow: 0 0 15px rgba(0,0,0,0.1);
      position: relative;
      .mobile-menu-main-item {
        display: block;
        color: #fff;
        padding: 15px 25px;
        border-bottom: 1px solid #154682;
        font-weight: bold;
        @include transition;
        &:last-child {
          border: 0;
        }
        &:hover {
          text-decoration: none;
        }
        &:active {
          transform: scale(0.95);
        }
      }
      .close-menu {
        @include size(20px);
        position: absolute;
        top: 30px;
        right: 30px;
        @include image('../images/svg/close-white.svg');
        @include transition(300ms);
        transform: scale(0) rotateZ(45deg);
        transition-delay: 300ms;
      }
    }
    .mobile-menu-sub {
      padding-top: 15px;
      .mobile-menu-sub-item {
        display: block;
        color: #adcbe2;
        padding: 10px 25px;
        @include transition;
        &:hover {
          text-decoration: none;
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }
    &.opened {
      right: 0;
      .mobile-menu-main {
        .close-menu {
          transform: scale(1) rotateZ(0);
        }
      }
    }
  }
  .menu-backdrop {
    display: block;
    background-color: rgba(15,23,33,0.95);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9998;
    opacity: 0;
    pointer-events: none;
    @include transition;
    &.opened {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .mobile-menu .mobile-menu-sub a.btn {
    margin-left: 25px;
    margin-bottom: 25px;
  }
}
