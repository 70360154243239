.next-page {
  padding: 70px 0 85px 0;
  background-color: $vibrant-blue;
  text-align: center;
  position: relative;
  &::before {
    @include pseudo;
    width: 148px;
    height: 243px;
    top: -18px;
    left: 50%;
    @include image('../images/backgrounds/stethoscope-1.png');
    pointer-events: none;
    z-index: 1;
  }
  &::after {
    @include pseudo;
    width: 382px;
    height: 418px;
    bottom: -13px;
    left: 50%;
    @include image('../images/backgrounds/stethoscope-2.png');
    pointer-events: none;
    z-index: 1;
  }
  .next-page-headline {
    color: #fff;
    font-size: 30px;
  }
  .next-page-text {
    font-size: 18px;
    color: #8cb4e3;
    margin: auto;
    margin-bottom: 25px;
    line-height: 30px;
    a {
      color: #8cb4e3;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.small {
    .next-page-text {
      width: 30%;
    }
    &::before {
      margin-left: -320px;
    }
    &::after {
      margin-left: 160px;
    }
  }
  &.big {
    .next-page-text {
      width: 60%;
    }
    &::before {
      width: 121px;
      height: 198px;
      margin-left: -500px;
      top: -14px;
    }
    &::after {
      margin-left: 327px;
      width: 310px;
      height: 340px;
      bottom: -11px;
    }
  }
  &.smaller-text {
    .next-page-text {
      width: 40%;
    }
  }
}

@media (max-width: 1599px) {
  .next-page {
    &.big {
      &::after {
        margin-left: 288px;
      }
      &::before {
        margin-left: -460px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .next-page {
    padding: 50px 0 55px 0;
    &::after {
      width: 260px;
      height: 285px;
      bottom: -9px;
    }
    &::before {
      top: -11px;
      width: 96px;
      height: 158px;
    }
    .next-page-headline {
      width: 50%;
      margin: auto;
      margin-bottom: 25px;
      font-size: 25px;
    }
    .next-page-text {
      font-size: 16px;
      line-height: 24px;
    }
    &.big {
      &::after {
        width: 260px;
        height: 285px;
        bottom: -9px;
        margin-left: 200px;
      }
      &::before {
        top: -11px;
        width: 96px;
        height: 158px;
        margin-left: -350px;
      }
      .next-page-text {
        width: 50%;
      }
    }
  }
}

@media (max-width: 991px) {
  .next-page {
    &::after {
      width: 150px;
      height: 168px;
      bottom: -7px;
    }
    &::before {
      top: -9px;
      width: 66px;
      height: 110px;
    }
    &.small {
      &::after {
        margin-left: 220px;
      }
      &::before {
        margin-left: -290px;
      }
      .next-page-text {
        width: 50%;
      }
    }
    &.big {
      &::after {
        width: 150px;
        height: 168px;
        bottom: -7px;
      }
      &::before {
        top: -9px;
        width: 66px;
        height: 110px;
        margin-left: -320px;
      }
      .next-page-headline {
        width: 70%;
      }
      .next-page-text {
        width: 70%;
      }
    }
  }
}

@media (max-width: 767px) {
  .next-page {
    padding: 40px 0;
    &::before, &::after {
      display: none;
    }
    .next-page-headline {
      width: 100% !important;
      font-size: 22px;
    }
    .next-page-text {
      width: 100% !important;
    }
  }
}