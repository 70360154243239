.btn {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 14px 40px 12px 40px;
  @include transition;
  border: 0;
  &:focus {
    box-shadow: none;
  }
  &:active {
    transform: scale(0.95);
  }
}
.btn-blue {
  background-color: $blue;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #2681c4;
  }
}
.btn-dark-blue {
  background-color: $vibrant-blue;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #073771;
  }
}

.button-toolbar {
  .btn {
    &+.btn {
      margin-left: 26px;
    }
  }
}

@media (max-width: 991px) {
  .btn {
    padding: 11px 27px 9px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .btn {
    padding: 11px 20px 9px 20px;
    font-size: 15px;
  }
  .button-toolbar {
    .btn {
      &+.btn {
        margin-left: 5px;
      }
    }
  }
}