@mixin border-radius ($radius: 10px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin border-radius-extended ($radius1, $radius2, $radius3, $radius4) {
  -webkit-border-top-left-radius: $radius1;
  -webkit-border-top-right-radius: $radius2;
  -webkit-border-bottom-right-radius: $radius3;
  -webkit-border-bottom-left-radius: $radius4;
  -moz-border-radius-topleft: $radius1;
  -moz-border-radius-topright: $radius2;
  -moz-border-radius-bottomright: $radius3;
  -moz-border-radius-bottomleft: $radius4;
  border-top-left-radius: $radius1;
  border-top-right-radius: $radius2;
  border-bottom-right-radius: $radius3;
  border-bottom-left-radius: $radius4;
}
@mixin box-shadow ($x: 5px, $y: 5px, $blur: 10px, $color: rgba(0,0,0,0.5)) {
  -webkit-box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  box-shadow: $arguments;
}
@mixin transition ($duration: 200ms) {
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
  transition: all $duration ease;
}
@mixin inline-block {
  display: inline-block;
  vertical-align: top;
}
@mixin pseudo {
  content: "";
  position: absolute;
  display: block;
}
@mixin icon ($position) {
  background-image: url('../../images/sprite.png');
  background-position: $position;
  background-repeat: no-repeat;
  background-size: 750px 750px;
}
@mixin rotate ($value) {
  -ms-transform: rotate($value);
  -webkit-transform: rotate($value);
  transform: rotate($value);
}
@mixin image ($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
@mixin background-image ($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@mixin size($size) {
  width: $size;
  height: $size;
}
