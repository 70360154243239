.about-us {
  padding-bottom: 50px;
  .about-us-wrapper {
    padding-left: 90px;
  }
  .icon-item {
    margin-bottom: 70px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .about-us-img {
    position: absolute;
    left: -190px;
    top: -255px;
  }
}

@media (max-width: 1199px) {
  .about-us {
    padding: 20px 0 10px 0;
    .about-us-wrapper {
      padding-left: 0;
    }
    .about-us-img {
      left: -260px;
      top: -210px;
    }
  }
}

@media (max-width: 991px) {
  .about-us {
    .about-us-img {
      left: -130px;
      top: -30px;
      width: 500px;
    }
  }
}

@media (max-width: 767px) {
  .about-us {
    .about-us-img {
      display: none;
    }
    .icon-item {
      margin-bottom: 30px;
    }
  }
}