.place {
  background-color: $light-blue-2;
  padding: 105px 0 70px 0;
  .place-headline {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .place-option {
    padding-left: 105px;
    position: relative;
    .place-option-headline {
      font-size: 24px;
      margin-bottom: 5px;
    }
    .place-option-text {
      margin-bottom: 0;
      line-height: 1.6;
    }
    &::before {
      @include pseudo;
      @include size(80px);
      border: 2px solid $blue;
      border-radius: 100%;
      left: 0;
      top: 0;
    }
    .icon {
      fill: $blue;
      &.car {
        @include size(37px);
        left: 22px;
        top: 21px;
      }
      &.metro {
        @include size(44px);
        left: 18px;
        top: 16px;
      }
      &.tram {
        @include size(53px);
        left: 14px;
        top: 13px;
      }
    }
    &+.place-option {
      margin-top: 50px;
    }
  }
  .place-gallery {
    position: relative;
    right: -25px;
    top: -40px;
    text-align: right;
    .place-gallery-item {
      width: 160px;
      height: 130px;
      @include inline-block;
      border: 5px solid #fff;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      @include transition;
      &:hover {
        transform: translateY(-5px);
      }
      &+.place-gallery-item {
        margin-left: 5px;
      }
    }
  }
  .place-buttons {
    position: relative;
    text-align: center;
    width: 100%;
    top: -65px;
    pointer-events: none;
    .btn {
      pointer-events: auto;
      &+.btn {
        margin-left: 15px;
      }
    }
  }
}

.place-map {
  height: 300px;
  @include background-image('../images/mapa.jpg');
}

@media (max-width: 1599px) {
  .place-map {
    height: 350px;
  }
}

@media (max-width: 1199px) {
  .place {
    padding: 90px 0 30px 0;
    .place-headline {
      font-size: 26px;
    }
    .place-gallery {
      .place-gallery-item {
        width: 130px;
        height: 110px;
      }
    }
  }
}

@media (max-width: 991px) {
  .place {
    padding: 60px 0;
    .place-buttons {
      top: auto;
      padding-top: 60px;
    }
  }
}

@media (max-width: 767px) {
  .place {
    padding: 40px 0;
    .place-headline {
      font-size: 22px;
    }
    .place-gallery {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      .place-gallery-item {
        width: 91px;
        height: 70px;
        border-width: 3px;
        &+.place-gallery-item {
          margin-left: 0;
        }
      }
    }
    .place-option {
      padding-left: 70px;
      &::before {
        @include size(50px);
      }
      .icon {
        &.car {
          @include size(28px);
          left: 11px;
          top: 11px;
        }
        &.metro {
          @include size(30px);
          left: 10px;
          top: 8px;
        }
        &.tram {
          @include size(36px);
          left: 7px;
          top: 7px;
        }
      }
      .place-option-headline {
        font-size: 20px;
      }
    }
    .place-buttons {
      padding-top: 40px;
      .btn {
        width: 100%;
        &+.btn {
          margin: 10px 0 0 0;
        }
      }
    }
  }
}