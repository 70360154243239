.faq {
  background-color: $light-blue-2;
  text-align: center;
  padding-top: 145px;
  .faq-headline {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .faq {
    .faq-headline {
      font-size: 26px;
    }
  }
}

@media (max-width: 767px) {
  .faq {
    padding-top: 110px;
    .faq-headline {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}