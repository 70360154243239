.simple-section {
  .icon-items {
    padding: 75px 0 60px 0;
  }
}

@media (max-width: 1199px) {
  .simple-section {
    h2 {
      font-size: 26px;
    }
  }
}

@media (max-width: 767px) {
  .simple-section {
    padding-bottom: 0;
    h2 {
      font-size: 22px;
    }
    .icon-items {
      padding: 20px 0 0 0;
    }
    .icon-item {
      margin-bottom: 40px;
    }
  }
}