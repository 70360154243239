/*--open-sans--*/

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Regular.eot');
  src: url('../fonts/open-sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-Regular.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-Regular.svg#OpenSans') format('svg');
  font-weight: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Italic.eot');
  src: url('../fonts/open-sans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-Italic.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-Italic.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-Italic.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-Italic.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-SemiBold.eot');
  src: url('../fonts/open-sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-SemiBold.svg#OpenSans') format('svg');
  font-weight: 600;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-SemiBoldItalic.eot');
  src: url('../fonts/open-sans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-SemiBoldItalic.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-SemiBoldItalic.svg#OpenSans') format('svg');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Bold.eot');
  src: url('../fonts/open-sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-Bold.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-Bold.svg#OpenSans') format('svg');
  font-weight: bold;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-BoldItalic.eot');
  src: url('../fonts/open-sans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/open-sans/OpenSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/open-sans/OpenSans-BoldItalic.woff') format('woff'),
  url('../fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype'),
  url('../fonts/open-sans/OpenSans-BoldItalic.svg#OpenSans') format('svg');
  font-weight: bold;
  font-style: italic;
}

/*--rubik--*/

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Regular.eot');
  src: url('../fonts/rubik/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubik/Rubik-Regular.woff2') format('woff2'),
  url('../fonts/rubik/Rubik-Regular.woff') format('woff'),
  url('../fonts/rubik/Rubik-Regular.ttf') format('truetype'),
  url('../fonts/rubik/Rubik-Regular.svg#Rubik') format('svg');
  font-weight: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Italic.eot');
  src: url('../fonts/rubik/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubik/Rubik-Italic.woff2') format('woff2'),
  url('../fonts/rubik/Rubik-Italic.woff') format('woff'),
  url('../fonts/rubik/Rubik-Italic.ttf') format('truetype'),
  url('../fonts/rubik/Rubik-Italic.svg#Rubik') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Medium.eot');
  src: url('../fonts/rubik/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubik/Rubik-Medium.woff2') format('woff2'),
  url('../fonts/rubik/Rubik-Medium.woff') format('woff'),
  url('../fonts/rubik/Rubik-Medium.ttf') format('truetype'),
  url('../fonts/rubik/Rubik-Medium.svg#Rubik') format('svg');
  font-weight: 500;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-MediumItalic.eot');
  src: url('../fonts/rubik/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rubik/Rubik-MediumItalic.woff2') format('woff2'),
  url('../fonts/rubik/Rubik-MediumItalic.woff') format('woff'),
  url('../fonts/rubik/Rubik-MediumItalic.ttf') format('truetype'),
  url('../fonts/rubik/Rubik-MediumItalic.svg#Rubik') format('svg');
  font-weight: 500;
  font-style: italic;
}

