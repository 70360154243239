.form-group {
  margin-bottom: 20px;
  position: relative;
  &.has-error {
    .form-control {
      border-color: $red;
    }
    .form-message {
      color: $red;
    }
  }
}
.form-control {
  height: 50px;
  background-color: $light-blue-2;
  border-radius: 3px;
  color: #848690;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid $light-blue-2;
  &:focus {
    background-color: $light-blue-2;
    box-shadow: none;
    border-color: $dark-blue;
    color: $dark-blue;
  }
}
textarea {
  &.form-control {
    min-height: 130px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.control-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
.form-message {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: -24px;
}

.custom-checkbox {
  margin-bottom: 20px;
  input {
    display: none;
    &+label {
      position: relative;
      border: 2px solid #d2e1ec;
      border-radius: 4px;
      font-weight: 600;
      line-height: 18px;
      padding: 10px 10px 10px 70px;
      min-height: 60px;
      display: table;
      width: 100%;
      cursor: pointer;
      @include transition;
      .custom-checkbox-text {
        display: table-cell;
        vertical-align: middle;
      }
      .icon {
        @include size(38px);
        fill: $blue;
        top: 50%;
        transform: translateY(-50%) rotateY(0);
        left: 15px;
        @include transition(1000ms);
      }
    }
    &:checked {
      &+label {
        border-color: $blue;
        .icon {
          transform: translateY(-50%) rotateY(-360deg);
        }
      }
    }
  }
}