.references {
  text-align: center;
  .references-headline {
    font-size: 30px;
  }
  .references-text {
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
  }
  .references-logos {
    margin-bottom: 60px;
    .references-logo {
      width: 115px;
      height: 65px;
      background: no-repeat center center;
      background-size: contain;
      @include inline-block;
      margin: 20px;
      @include transition;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  &.references-subpage {
    padding-top: 0;
    .references-logos {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1199px) {
  .references {
    .references-text {
      width: 80%;
    }
    .references-headline {
      font-size: 26px;
    }
  }
}

@media (max-width: 991px) {
  .references {
    .references-text {
      width: 100%;
      margin-bottom: 30px;
    }
    .references-logos {
      margin-bottom: 30px;
      .references-logo {
        width: 93px;
        height: 45px;
        margin: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .references {
    .references-headline {
      font-size: 22px;
    }
    .references-logos {
      margin-bottom: 20px;
      .references-logo {
        width: 70px;
        height: 40px;
        margin: 10px;
      }
    }
    &.references-subpage {
      padding: 20px 0;
    }
  }
}