.benefits {
  padding: 0 0 150px 0;
  border-bottom: 1px solid $grey2;
}

@media (max-width: 991px) {
  .benefits {
    padding-bottom: 20px;
    .icon-item {
      margin-bottom: 45px;
    }
  }
}

@media (max-width: 767px) {
  .benefits {
    padding-bottom: 0;
    .icon-item {
      margin-bottom: 40px;
    }
  }
}