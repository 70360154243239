footer {
  padding: 75px 0;
  background-color: #00244f;
  position: relative;
  overflow: hidden;
  &::before {
    @include pseudo;
    width: 1010px;
    height: 160px;
    @include image('../images/svg/logo-shape.svg');
    left: 50%;
    top: -60px;
    margin-left: 60px;
    opacity: 0.1;
  }
  .footer-info {
    .footer-info-headline {
      color: #fff;
      font-size: 24px;
      margin-bottom: 25px;
    }
    .footer-info-text {
      font-size: 14px;
      line-height: 24px;
      color: #8cb4e3;
      margin: 0;
    }
    .footer-info-link {
      color: #8cb4e3;
      text-decoration: underline;
      &:hover {
        color: #8cb4e3;
        text-decoration: none;
      }
    }
  }
  .footer-logo {
    width: 235px;
    height: 37px;
    @include image('../images/logo-white.png');
    display: block;
    float: right;
  }
  .credits {
    position: absolute;
    right: 15px;
    bottom: 0;
    .copyright {
      @include inline-block;
      color: #537aa9;
      font-size: 14px;
      line-height: 31px;
    }
    .socials {
      @include inline-block;
      margin-left: 55px;
      font-size: 0;
      .socials-item {
        @include inline-block;
        @include size(31px);
        border-radius: 3px;
        position: relative;
        @include transition;
        .icon {
          @include size(14px);
          fill: #fff;
        }
        &+.socials-item {
          margin-left: 10px;
        }
        &.facebook {
          background-color: #304e98;
          .icon {
            left: 9px;
            top: 9px;
          }
          &:hover {
            background-color: #264185;
          }
        }
        &.linkedin {
          background-color: #006fb3;
          .icon {
            left: 9px;
            top: 9px;
          }
          &:hover {
            background-color: #005b92;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  footer {
    .footer-logo {
      width: 170px;
      height: 27px;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .credits {
      .socials {
        margin-left: 30px;
      }
    }
    .footer-info {
      .footer-info-headline {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  footer {
    padding: 40px 0;
    &::before {
      display: none;
    }
    .footer-info {
      .footer-info-text  {
        margin-bottom: 30px;
      }
      .footer-info-headline {
        margin-bottom: 10px;
      }
    }
    .credits {
      position: relative;
      right: auto;
      bottom: auto;
      text-align: center;
      .socials {
        margin-left: 0;
      }
      .copyright {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .footer-logo {
      float: none;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 15px;
    }
  }
}