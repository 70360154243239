.contact {
  padding: 20px 0 70px 0;
  position: relative;
  &::before {
    @include pseudo;
    width: 788px;
    height: 453px;
    left: 50%;
    top: -160px;
    margin-left: -75px;
    @include image('../images/backgrounds/contact.png');
  }
  .contact-headline {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .contact-text {
    margin: 0;
  }
  .contact-phone {
    padding-left: 85px;
    position: relative;
    .icon {
      @include size(50px);
      left: 0;
      top: 50%;
      fill: $blue;
      margin-top: -25px;
    }
  }
  .contact-phone-link {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    display: block;
    line-height: 1.4;
  }
}

@media (max-width: 1599px) {
  .contact {
    &::before {
      width: 670px;
      height: 387px;
      top: -110px;
    }
  }
}

@media (max-width: 1199px) {
  .contact {
    &::before {
      width: 530px;
      height: 315px;
      top: -30px;
      margin-left: -35px;
    }
    .contact-headline {
      font-size: 26px;
    }
    .contact-phone-link {
      font-size: 20px;
    }
    .contact-phone {
      padding-left: 60px;
      .icon {
        @include size(40px);
        margin-top: -20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .contact {
    padding-bottom: 60px;
    .contact-phone {
      margin-top: 40px;
    }
    &::before {
      top: -230px;
      margin-left: -165px;
    }
  }
}

@media (max-width: 767px) {
  .contact {
    padding-bottom: 40px;
    &::before {
      display: none;
    }
    .contact-headline {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}