.modal-content {
  border-radius: 0;
  border: 0;
  padding: 55px 40px 40px;
  .modal-headline {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .modal-close {
    position: absolute;
    @include size(20px);
    @include image('../images/svg/close-blue.svg');
    top: 30px;
    right: 30px;
    cursor: pointer;
    opacity: .2;
    @include transition;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

.modal-backdrop {
  background-color: #001228;
  &.show {
    opacity: 0.8;
  }
}

.modal-buton-container {
  text-align: right;
}

@media (max-width: 575px) {
  .modal-content {
    padding: 40px 15px 15px;
    .modal-headline {
      font-size: 22px;
    }
    .modal-close {
      @include size(15px);
      top: 15px;
      right: 15px;
    }
  }
}

.modal {
  z-index: 10000;
}