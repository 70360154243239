.medbook {
  text-align: center;
  padding-top: 0;
  padding-bottom: 60px;
  .medbook-headline {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .icon-item {
    text-align: left;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }
  .medbook-img {
    margin-left: -100px;
    margin-top: 50px;
  }
}

@media (max-width: 1199px) {
  .medbook {
    .medbook-headline {
      font-size: 26px;
    }
    .medbook-img {
      width: 510px;
    }
  }
}

@media (max-width: 991px) {
  .medbook {
    overflow: hidden;
    .medbook-img {
      width: 660px;
      margin-top: 80px;
    }
  }
}

@media (max-width: 767px) {
  .medbook {
    padding: 30px 0 0 0;
    .medbook-headline {
      font-size: 22px;
      margin-bottom: 40px;
    }
    .icon-item {
      margin-bottom: 50px;
    }
    .medbook-img {
      display: none;
    }
  }
}