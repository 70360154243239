html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0
}
html, body {
  height: 100%;
}
body {
  background-color: #fff;
  color: $base-color;
  font-size: 16px;
  text-align: left;
  line-height: 2;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  &.opened {
    overflow: hidden;
  }
}

/*----------CUSTOMIZED-ROWS----------*/

.row-0 {
  margin-right: 0;
  margin-left: 0;
  > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
  > [class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-30 {
  margin-right: -30px;
  margin-left: -30px;
  > [class^="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.row-45 {
  margin-right: -45px;
  margin-left: -45px;
  > [class^="col-"] {
    padding-left: 45px;
    padding-right: 45px;
  }
}

/*----------GENERAL----------*/

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: $dark-blue;
  b, strong {
    color: $blue;
    font-weight: 500;
  }
}
h2, h3, h4, h5, h6 {
  margin-bottom: 25px;
}
p, ul, ol {
  &+h2, &+h3, &+h4, &+h5, &+h6 {
    padding-top: 15px;
  }
}
h1 {
  font-size: 36px;
  margin-bottom: 40px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
b, strong {
  font-weight: bold;
}
p {
  margin-bottom: 25px;
}
i, em {
  font-style: italic;
}
ul {
  padding: 0;
  margin: 0 0 25px 0;
  li {
    list-style-type: none;
    position: relative;
    padding: 0 0 5px 35px;
    &::before {
      @include pseudo;
      @include size(6px);
      background-color: $blue;
      border-radius: 100%;
      left: 14px;
      top: 14px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
ol {
  margin: 0 0 25px 0;
  counter-reset: item;
  li {
    padding: 0 0 5px 35px;
    list-style-type: none;
    position: relative;
    counter-increment: item;
    &::before {
      @include size(20px);
      content: counter(item, lower-alpha) ")";
      position: absolute;
      left: 11px;
      top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
a {
  color: $blue;
  &:hover {
    color: $dark-blue;
  }
}
.icon {
  position: absolute;
}
.icon-helmet,
.icon-tool {
  top: 0;
  transform: translate(-50%,-50%);
}
section {
  padding: 90px 0;
}
.no-margin {
  margin: 0;
}
.padding-top-0 {
  padding-top: 0;
}

.grecaptcha-badge {
  right: -5000px !important;
}

/*media queries*/

@media (min-width: 1920px) {
  .landing {
    background-size: cover;
    background-position: center center;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 1599px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 24px;
  }
  .row-45, .row-30 {
    margin-right: -15px;
    margin-left: -15px;
    > [class^="col-"] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media (max-width: 991px) {
  section {
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  body {
    line-height: 1.7;
  }
  section {
    padding: 40px 0;
  }
}